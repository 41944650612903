import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/smile-again.scss"

const SmileAgainProgram = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    speed: 2000
  }
  return (
    <Layout>
      <SEO
        title="Smile Again Program"
        description="It is time for a brand new, healthy, beautiful smile at  Oral Surgery & Dental Implant Specialists of Cincinnati."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-hero-image"
            alt="smile again banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-hero-image-mobile"
            alt="smile again banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>Meet Scott, Our First Smile Again Recipient!</h1>

            <p>
              We are thrilled to introduce Scott, a resilient 57-year-old from
              Miamisburg, OH, as Oral Surgery & Dental Implant Specialists of
              Cincinnati’s first-ever Smile Again recipient.
            </p>
            <p>
              Scott's journey has been marked by significant challenges,
              particularly in the realm of dental health. In 2014, he faced
              esophageal cancer, and the aftermath of chemotherapy and radiation
              left his teeth brittle and prone to breaking. The absence of a
              stomach, used for reconstructing his esophagus, has made eating a
              challenge. Scott's desire to enjoy a healthy diet, coupled with
              the profound impact on his confidence and interactions with loved
              ones, led him to apply for our Smile Again program.
            </p>
            <p>
              In Scott's own words: "A set of new teeth would be amazing. No one
              can look me in the eyes anymore, including my wife and grandkids.
              My family feels embarrassed for me, and I cannot smile at jokes or
              have complete meals without food getting stuck. This would help
              with my overall health and confidence and change my life for the
              good for once in the past 9 years. This would help me be more
              healthy, and I have worked very hard to make it to this point in
              my life."
            </p>
            <p>
              Oral Surgery & Dental Implant Specialists of Cincinnati has
              partnered with restorative dentist Dr. Heather Lucas, Sanvon with
              SK Dental Labs, and Straumann Implants to help give Scott the
              smile he deserves. We look forward to supporting Scott as he faces
              the world with a radiant smile!
            </p>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container small">
            <Slider {...settings}>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-1"
                  alt="program recipient"
                />
              </div>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-2"
                  alt="program recipient"
                />
              </div>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-3"
                  alt="program recipient"
                />
              </div>
              <div>
                <video
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  controls>
                  <source
                    src="https://res.cloudinary.com/nuvolum/video/upload/v1699992734/CIOS/DEV/smile-again-recipient-4.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Slider>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              Follow Scott’s journey on our{" "}
              <a
                href="https://www.facebook.com/cincinnatioralsurgeons"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/cincinnatioralsurgeons/?hl=en"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              pages!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-cincinnati-oh/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileAgainProgram
